import React from 'react';
import { Link } from 'gatsby';
import UniversalCookie from 'universal-cookie';
import "./cookies.css";

const Universal_Cookie = new UniversalCookie();
const currentYear = new Date();
const nextYear = new Date();
nextYear.setFullYear(currentYear.getFullYear() + 1);

class Cookies extends React.Component{  
    constructor(){
        super();

        if(Universal_Cookie.get('CookieAlert')  === "false"){
            this.state = {
                showCookieAlert: false,
                showModal: false,
                settingsToggle: true,
                StatistikToggle: true,
                hiddenClassName: "hidden"
            }
        }else {
            this.state = {
                showCookieAlert: true, 
                showModal: false,
                settingsToggle: true,
                StatistikToggle: true,
                hiddenClassName: "hidden"
            }
        }

        this.acceptAllCookies = this.acceptAllCookies.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.denyAllCookies = this.denyAllCookies.bind(this);
        this.settingsToggleSwitch = this.settingsToggleSwitch.bind(this);
        this.StatistikToggleSwitch = this.StatistikToggleSwitch.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidMount(){
        if (this.state.showCookieAlert){
            this.setState({
                hiddenClassName: "block w-screen h-scrren z_number_3"
            });
        }
    }

    acceptAllCookies(){
        this.setState({showCookieAlert: false,  showModal: false,});
        Universal_Cookie.set('CookieAlert', false, {
            path: '/',
            expires: nextYear,
        });
        Universal_Cookie.set("CookieConsent", "all", {
            path: '/',
            expires: nextYear,
        });
    }

    openModal(){
        this.setState({showModal: true});
    }

    closeModal(){
        this.setState({showModal: false});
    }

    denyAllCookies(){
        this.setState({showCookieAlert: false,  showModal: false});
        Universal_Cookie.set('CookieAlert', false);
        Universal_Cookie.set("CookieConsent", "none", {
            path: '/',
            expires: nextYear,
        });
    }

    settingsToggleSwitch() {
        if(this.state.settingsToggle === true){
            this.setState({settingsToggle: false});
        }
        
        if (this.state.settingsToggle === false){
            this.setState({settingsToggle: true});
        }
    }

    StatistikToggleSwitch() {
        if(this.state.StatistikToggle === true){
            this.setState({StatistikToggle: false});
        }
        
        if (this.state.StatistikToggle === false){
            this.setState({StatistikToggle: true});
        }
    }

    save(){
        this.setState({showCookieAlert: false,  showModal: false,});
        Universal_Cookie.set('CookieAlert', false, {
            path: '/',
            expires: nextYear,
        });

        if(this.state.settingsToggle === true && this.state.StatistikToggle === false){
            //accept only settings cookies
            Universal_Cookie.set("CookieConsent", "settings", {
                path: '/',
                expires: nextYear,
            });
        }

        if(this.state.settingsToggle === false && this.state.StatistikToggle === true){
            //accept only statistic cookies
            Universal_Cookie.set("CookieConsent", "statistic", {
                path: '/',
                expires: nextYear,
            });
        }

        if(this.state.settingsToggle === false && this.state.StatistikToggle === false){
            // deny all cookies
            Universal_Cookie.set("CookieConsent", "none", {
                path: '/',
                expires: nextYear,
            });
        }

        if(this.state.settingsToggle === true && this.state.StatistikToggle === true){
            //accept all cookies
            Universal_Cookie.set("CookieConsent", "all", {
                path: '/',
                expires: nextYear,
            });
        }
    }

    render(){
            return(
                <div className={this.state.hiddenClassName}>
                    <div>
                        {this.state.showCookieAlert ? 
                            <section className="fixed bottom-0 left-0 right-0 z_number_2 bg-gradient-to-br from-blue-900custome to-blue-700custome">
                            <div className="container mx-auto">
                                <div className="w-full lg:w-2/3 xl:w-1/2 mx-auto px-4 py-12 ">
                                    <h3 className="text-white text-xl font-semibold">We use cookies</h3>
                                    <p className="mt-8 text-white">crediflow.com uses necessary cookies for the website to work well for you. We also use cookies for web analytics so we can improve our website.</p>
                                    <div className="mt-8">
                                        <Link to="/cookies" className='flex items-center text-white font-bold'>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="h-8 w-8" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5z"/>
                                            </svg>
                                            <span className="ml-4">What are cookies?</span>
                                        </Link>
                                    </div>
                                    <div className="mt-8 flex flex-col lg:flex-row ">
                                        <button onClick={this.openModal} className="px-6 py-2 rounded-full border-2 border-white shadow-md hover:opacity-90 text-white font-semibold">Manage cookies</button>
                                        <button onClick={this.acceptAllCookies} className="px-6 py-2 rounded-full mt-4 lg:mt-0 lg:ml-3 border-2 border-white text-blue-900 font-semibold bg-white shadow-md hover:opacity-90">Accept cookies</button>
                                    </div>
                                </div>
                            </div>
                        </section>
                        : ""}
                    </div>

                    {this.state.showModal ? 
                        <div className='fixed top-0 left-0 right-0 bottom-0 z_number_1 h-screen w-screen p-2 flex justify-center items-center ' style={{background: "rgba(0,0,0,0.5)"}}>
                            <div className="w-full max-w-2xl rounded-lg shadow-md bg-white p-4">
                                <div className="flex flex-row items-start justify-between">
                                    <h3 className="text-lg font-bold">Manage cookies</h3>
                                    <button className="p-2 rounded-full hover:bg-blue-custome text-blue-custome hover:text-white" onClick={this.closeModal}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-6 h-6" viewBox="0 0 16 16">
                                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                                        </svg>
                                    </button>
                                </div>
                                <p className="mt-6">Here you can choose which cookies you want to approve.</p>
                                <div className="mt-6">
                                    <Link to="/cookies" className="flex items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="h-5 w-5" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5z"/>
                                        </svg>
                                        <span className="ml-4 underline">What are cookies?</span>
                                    </Link>
                                </div>
                                <div className="mt-8 flex flex-row">
                                    <button onClick={this.acceptAllCookies} className="px-8 py-2 rounded-full text-blue-custome border border-blue-custome bg-gray-50 shadow-md hover:bg-blue-custome hover:text-white hover:opacity-90">Accept all</button>
                                    <button onClick={this.denyAllCookies} className="ml-4 px-8 py-2 rounded-full text-blue-custome border border-blue-custome bg-gray-50 shadow-md hover:bg-blue-custome hover:text-white hover:opacity-90">Deny all</button>
                                </div>

                                <div className="mt-6 rounded-md bg-gray-200 px-4 py-6 ">
                                    <div className="flex items-center">
                                        <button className="opacity-60 w-10 h-4 flex items-center bg-blue-300 rounded-full cursor-pointer">
                                            <div className="bg-blue-custome translate-x-4 h-6 w-6 rounded-full shadow-md transform duration-300 ease-in-out"></div>
                                        </button>     
                                        <h3 className="ml-4">Necessary</h3>               
                                    </div>

                                    <div className="mt-6 flex items-center">
                                        <button onClick={this.settingsToggleSwitch} className={!this.state.settingsToggle ? "w-10 h-4 flex items-center bg-gray-400 rounded-full cursor-pointer" : "w-10 h-4 flex items-center bg-blue-300 rounded-full cursor-pointer"}>
                                            <div className={
                                                !this.state.settingsToggle ? 
                                                "bg-white h-6 w-6 rounded-full shadow-md transform duration-300 ease-in-out" 
                                                : 
                                                "bg-blue-custome translate-x-4 h-6 w-6 rounded-full shadow-md transform duration-300 ease-in-out"
                                                }
                                            ></div>
                                        </button>     
                                        <h3 className="ml-4">Settings</h3>               
                                    </div>

                                    <div className="mt-6 flex items-center">
                                        <button onClick={this.StatistikToggleSwitch} className={!this.state.StatistikToggle ? "w-10 h-4 flex items-center bg-gray-400 rounded-full cursor-pointer" : "w-10 h-4 flex items-center bg-blue-300 rounded-full cursor-pointer"}>
                                            <div className={
                                                !this.state.StatistikToggle ? 
                                                "bg-white h-6 w-6 rounded-full shadow-md transform duration-300 ease-in-out" 
                                                : 
                                                "bg-blue-custome translate-x-4 h-6 w-6 rounded-full shadow-md transform duration-300 ease-in-out"
                                                }
                                            ></div>
                                        </button>     
                                        <h3 className="ml-4">Statistics</h3>               
                                    </div>

                                </div>

                                <button onClick={this.save} className="mt-6 px-6 py-2 rounded-full text-white border border-blue-custome bg-blue-custome shadow-md hover:opacity-90">Save</button>
                            </div>
                        </div> 
                    : ""}
                </div>
            );
    }
}
export default Cookies;